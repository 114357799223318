<template>
  <div>
    <input type="radio" :value="value" v-bind="$attrs" :checked="checked" @change="$emit('change', $event.target.value)">
    <label :for="$attrs.id">{{label}}</label>
  </div>
</template>

<script>
export default {
    name: 'InputRadio',
    inheritAttrs: false,
    model: {
        prop: 'checked',
        event: 'change'
    },
    props: {
        label: { type: String, default: '', required: true },
        value: { type: String, default: undefined },
        checked: Boolean
    }
}
</script>

<style lang="postcss" scoped>
[type="radio"]:checked,
[type="radio"]:not(:checked) {
    position: absolute;
    left: -9999px;
}
[type="radio"]:checked + label,
[type="radio"]:not(:checked) + label
{
    position: relative;
    padding-left: 28px;
    cursor: pointer;
    line-height: 20px;
    display: inline-block;
    color: #666;
    font-size: 14px;
}
[type="radio"]:checked + label:before,
[type="radio"]:not(:checked) + label:before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 18px;
    height: 18px;
    border: 2px solid #2c6ecb;
    border-radius: 100%;
    background: #fff;
}
[type="radio"]:not(:checked) + label:before {
  border: 2px solid #8c9196;
}
[type="radio"]:checked + label:after,
[type="radio"]:not(:checked) + label:after {
    content: '';
    width: 10px;
    height: 10px;
    background: #2c6ecb;
    position: absolute;
    top: 4px;
    left: 4px;
    border-radius: 100%;
    -webkit-transition: all 0.2s ease;
    transition: all 0.2s ease;
}
[type="radio"]:not(:checked) + label:after {
    opacity: 0;
    -webkit-transform: scale(0);
    transform: scale(0);
}
[type="radio"]:checked + label:after {
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1);
}
</style>
