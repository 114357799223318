<template>
    <div>
        <label :for="inputname" class="block text-sm font-bold"><slot name="title"/></label>
        <div class="mt-1">
            <input v-bind:value="value" v-on:input="$emit('input', $event.target.value)" :type="inputtype" :readonly="readonly" :name="inputname" :id="inputid" :placeholder="inputplaceholder" class="focus:ring-primary focus:border-primary block w-full sm:text-sm border-gray-200 rounded-md">
        </div>
    </div>
</template>

<script>
export default {
    name: 'InputSmall',
    props: ['value', 'input', 'inputtype', 'inputname', 'inputid', 'inputplaceholder', 'readonly']
}
</script>
