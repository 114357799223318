<template>
    <div class="flex justify-between border-dashed border-b py-5">
        <dropdown v-model="openingHour.day">
            <template #options>
            <option :value="1">{{ $t('dashboard.monday') }}</option>
            <option :value="2">{{ $t('dashboard.tuesday') }}</option>
            <option :value="3">{{ $t('dashboard.wednesday') }}</option>
            <option :value="4">{{ $t('dashboard.thursday') }}</option>
            <option :value="5">{{ $t('dashboard.friday') }}</option>
            <option :value="6">{{ $t('dashboard.saturday') }}</option>
            <option :value="0">{{ $t('dashboard.sunday') }}</option>
            </template>
        </dropdown>
        <time-period :openingHour="openingHour" @addTime="$emit('addTime', openingHour.day)" @remove="$emit('remove')"/>
    </div>
</template>

<script>
import TimePeriod from '@/components/ui/TimePeriod.vue'
import Dropdown from '@/components/ui/Dropdown.vue'

export default {
    components: {
        TimePeriod,
        Dropdown
    },
    props: {
        openingHour: {
            type: Object
        }
    }
}
</script>
