<template>
  <div>
    <div class="grid grid-cols-2 justify-evenly">
      <h4 class="mb-5">{{ $t('dashboard.metafields') }}</h4>
      <div class="text-right">
        <button type="button" @click="showAddModal" class="rounded bg-primary w-2/6 text-white text-xs py-0 h-2/3">{{ $t("dashboard.metafields_btn_text") }}</button>
      </div>
    </div>
    <div>
      <vue-good-table
        :rows="metafieldDefinitionList.metafieldDefinitions"
        :columns="columns"
        theme="deliveryboosting"
        :isLoading="isLoadingMetafieldDefinitions"
        :sort-options="{
          enabled: false,
        }
        ">
        <template slot="table-row" slot-scope="props">
          <div v-if="props.column.field == 'name'">
            <p class="text-black">{{props.row._data.name}}</p>
            <p class="text-xs">{{props.row._data.description}}</p>
          </div>
          <div v-if="props.column.field == 'fieldType'">
            <p>{{props.row._data.fieldType}}</p>
          </div>
          <div v-if="props.column.field == 'actions'" class="text-right">
            <button class="hover:text-primary focus:outline-none ml-4" type="button" @click="handleEdit(props)" aria-label="Edit Metafield">
              <icon-edit class="h-6"/>
            </button>
            <button class="hover:text-primary focus:outline-none ml-4" type="button" @click="handleDelete(props)" aria-label="Delete Metafield">
              <icon-trash class="h-6"/>
            </button>
          </div>
        </template>
      </vue-good-table>
    </div>
    <transition name="slide-fade">
      <add-metafield v-if="isAddModalOpen" @close="closeAddModal" />
    </transition>
    <transition name="slide-fade">
      <edit-metafield v-if="isEditModalOpen" @close="closeEditModal" :metafieldDefinition="metafieldDefinition" />
    </transition>
    <transition name="slide-fade">
      <delete-metafield v-if="isDeleteModalOpen" @close="isDeleteModalOpen = false" :metafield="metafieldDefinition"/>
    </transition>
  </div>
</template>

<script>
import 'vue-good-table/dist/vue-good-table.css'
import { VueGoodTable } from 'vue-good-table'
import DeleteMetafield from '../../components/modals/DeleteMetafield.vue'
import IconTrash from '../../components/icons/IconTrash.vue'
import IconEdit from '../../components/icons/IconEdit.vue'
import AddMetafield from '@/components/modals/AddMetafield.vue'
import EditMetafield from '@/components/modals/EditMetafield.vue'
export default {
    name: 'SettingsMetafields',
    components: { AddMetafield, EditMetafield, DeleteMetafield, VueGoodTable, IconTrash, IconEdit },
    watch: {
        restaurant: {
            immediate: true,
            async handler () {
                clearInterval(this.interval)
                try {
                    const that = this
                    that.metafieldDefinitionList = await this.restaurant.getMetafieldDefinitionList()
                    const metafieldDefinitions = that.metafieldDefinitionList.metafieldDefinitions
                    // adding interval here for the loading state of the table
                    // can be remove if unnecessary
                    that.interval = setInterval(function () {
                        if (metafieldDefinitions.length > 0) {
                            that.isLoadingMetafieldDefinitions = false
                            clearInterval(that.interval)
                        }
                    }, 1000)

                    // clear interval if there is no data found after 5 seconds
                    setTimeout(function () {
                        if (metafieldDefinitions.length === 0) {
                            that.isLoadingMetafieldDefinitions = false
                            clearInterval(that.interval)
                        }
                    }, 5000)
                } catch (error) {
                    console.warn(error)
                }
            }
        }
    },
    computed: {
        restaurant () {
            return this.$store.state.restaurant
        }
    },
    data () {
        return {
            metafieldDefinitionList: {
                metafieldDefinitions: []
            },
            isLoadingMetafieldDefinitions: true,
            isMetafieldDefinitionsEmpty: false,
            isAddModalOpen: false,
            isEditModalOpen: false,
            isDeleteModalOpen: false,
            isEdit: false,
            modalInitialData: null,
            metafieldDefinition: {
                name: '',
                key: '',
                description: '',
                type: '',
                fieldType: '',
                quantity: '',
                textType: '',
                regexp: '',
                minLength: '',
                maxLength: '',
                restrictedUrl: '',
                measurementType: '',
                measurementUnit: '',
                minMeasurement: '',
                maxMeasurement: '',
                numberType: '',
                minNumber: '',
                maxNumber: '',
                timestampType: '',
                minTimestamp: '',
                maxTimestamp: ''
            },
            columns: [
                {
                    label: 'Definitionsname',
                    field: 'name'
                },
                {
                    label: 'Inhaltsart',
                    field: 'fieldType'
                },
                {
                    label: '',
                    field: 'actions'
                }
            ]
        }
    },
    methods: {
        showAddModal () {
            this.isAddModalOpen = true
        },
        showEditModal () {
            this.isEditModalOpen = true
        },
        closeAddModal () {
            this.isAddModalOpen = false
        },
        closeEditModal () {
            this.isEditModalOpen = false
        },
        onRowClick (params) {
            // console.log('params', params)
            this.isEdit = true
            this.metafieldDefinition = params.row
            this.showModal()
        },
        handleEdit (props) {
            console.log('props', props)
            this.metafieldDefinition = props.row._data
            this.showEditModal()
        },
        handleDelete (props) {
            this.metafieldDefinition = props.row._data
            this.isDeleteModalOpen = true
        },
        clearMetafieldDefintion () {
            for (const key in this.metafieldDefinition) {
                if (Object.hasOwnProperty.call(this.metafieldDefinition, key)) {
                    this.metafieldDefinition[key] = ''
                }
            }
        }
    }
}
</script>

<style>
  .vgt-inner-wrap {
      @apply shadow-none;
  }
  .deliveryboosting thead tr th {
      background: transparent !important;
      @apply text-gray-500;
  }
  .deliveryboosting, .deliveryboosting tr th, .deliveryboosting tr td {
      border: none !important;
  }
  .deliveryboosting thead tr, .deliveryboosting tbody tr {
      @apply border-b;
  }
  .deliveryboosting tbody tr td {
      @apply py-6 align-middle;
      @apply text-gray-500;
  }
  .slide-fade-enter-active {
    transition: all 0.5s ease;
  }
  .slide-fade-leave-active {
    transition: all 0.5s ease;
  }
  .slide-fade-enter, .slide-fade-leave-to
  /* .slide-fade-leave-active below version 2.1.8 */ {
    transform: translateY(100px);
    opacity: 0;
  }
</style>
