<template>
    <modal @close="close">
        <template v-if="!metafieldDefinition.name" #title>{{ $t('dashboard.metafields_modal_title') }}</template>
        <template v-else #title>{{ metafieldDefinition.name }}</template>
        <template #interactive><button-b @click.native="saveAndClose" class="bg-primary rounded text-white text-sm">{{ $t('dashboard.add') }}</button-b></template>
        <template #body>
          <settings-metafield-form v-model="metafieldDefinition" />
        </template>
      </modal>
</template>

<script>
import Modal from '@/components/dashboard/Modal.vue'
import ButtonB from '@/components/ui/ButtonB.vue'
import SettingsMetafieldForm from '@/components/dashboard/SettingsMetafieldForm.vue'
import { MetafieldDefinition } from 'delivery-boosting-2021-model'

export default {
    name: 'AddMetafield',
    components: {
        Modal,
        ButtonB,
        SettingsMetafieldForm
    },
    data () {
        return {
            metafieldDefinition: {
                name: '',
                key: '',
                description: '',
                type: '',
                fieldType: '',
                quantity: '',
                textType: '',
                regexp: '',
                minLength: '',
                maxLength: '',
                restrictedUrl: '',
                measurementType: '',
                measurementUnit: '',
                minMeasurement: '',
                maxMeasurement: '',
                numberType: '',
                minNumber: '',
                maxNumber: '',
                timestampType: '',
                minTimestamp: '',
                maxTimestamp: ''
            }
        }
    },
    computed: {
        restaurant () {
            return this.$store.state.restaurant
        }
    },
    methods: {
        async saveAndClose () {
            const metafieldDefinitionList = await this.restaurant.getMetafieldDefinitionList()
            await metafieldDefinitionList.addMetafieldDefinition(new MetafieldDefinition(this.metafieldDefinition))
            this.close()
        },
        close () {
            this.$emit('close')
        }
    }

}
</script>
