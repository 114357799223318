<template>
    <modal @close="close">
        <template #title>{{ $t('dashboard.metafields_modal_delete_title1') }}<span class="text-primary">{{metafield.name}}</span>{{ $t('dashboard.metafields_modal_delete_title2') }}</template>
        <template #body>
            <button-b :loading="loading" @click.native="confirmDeletion" class="bg-primary">{{ $t('dashboard.delete') }}</button-b>
            <button-b @click.native="close" class="bg-gray-600 ml-2" style="float: center">{{ $t('dashboard.cancel') }}</button-b>
        </template>
    </modal>
</template>

<script>
import ButtonB from '../../components/ui/ButtonB.vue'
import Modal from '../../components/dashboard/Modal.vue'
import { MetafieldDefinition } from 'delivery-boosting-2021-model'

export default {
    data () {
        return {
            loading: false
        }
    },
    props: {
        metafield: {
            type: MetafieldDefinition
        }
    },
    computed: {
        restaurant () {
            return this.$store.state.restaurant
        }
    },
    methods: {
        close () {
            this.$emit('close')
        },
        async confirmDeletion () {
            this.loading = true
            const metafieldDefinitionList = await this.restaurant.getMetafieldDefinitionList()
            await metafieldDefinitionList.removeMetafieldDefinition(this.metafield.key)
            this.loading = false
            this.close()
        }
    },
    components: {
        ButtonB,
        Modal
    }
}
</script>
