<template>
    <div>
    <transition name="slide-fade">
    <add-location v-if="isModalVisible" :location="location" @close="closeModal" />
    </transition>
    <div class="p-10 bg-gray-600 flex flex-col justify-between min-h-16 relative overflow-hidden rounded-lg">
        <div class="flex justify-between relative z-10">
            <div>
                <h4 class="text-white">{{location.general.title}}</h4>
                <badge v-if="location.active == true" settings="bg-green mt-2">
                    {{ 'Aktiv' }}
                </badge>
                <badge v-else settings="bg-red mt-2">
                    {{ 'Inaktiv' }}
                </badge>
            </div>
            <div class="flex">
                <a @click="deleteAndSave" class="text-white hover:text-primary mr-4"><icon-trash class="h-8 cursor-pointer"/></a>
                <a @click="showModal" class="text-white hover:text-primary"><icon-cog class="h-8 cursor-pointer"/></a>
            </div>
        </div>
        <!--<div class="flex justify-between text-white mt-4 relative z-10">
            <span>{{ $t('dashboard.minOrderValue') }}:  </span>
            <span class="font-bold">{{$n(location.minOrderValue /100, 'currency')}}</span>
        </div>-->
        <div v-if="location.media[0]">
        <img class="left-0 top-0 absolute w-full h-full object-cover rounded-lg z-0 opacity-50" :src="location.media[0].url"/>
        </div>
        <div v-else>
        <img class="left-0 top-0 absolute w-full h-full object-cover rounded-lg z-0 opacity-50" src="https://images.unsplash.com/photo-1552566626-52f8b828add9?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1950&q=80"/>
        </div>
    </div>
    </div>
</template>

<script>
import IconCog from '../icons/IconCog.vue'
import IconTrash from '../icons/IconTrash.vue'
import Badge from '../ui/Badge.vue'
import { Location } from 'delivery-boosting-2021-model'
import AddLocation from '@/components/modals/AddLocation.vue'
export default {
    data () {
        return {
            isModalVisible: false
        }
    },
    components: { Badge, IconCog, IconTrash, AddLocation },
    name: 'LocationCard',
    props: {
        location: {
            type: Location
        }
    },
    methods: {
        showModal () {
            this.isModalVisible = true
        },
        closeModal () {
            this.isModalVisible = false
        },
        async deleteAndSave () {
            this.$emit('remove')
        }
    }
}
</script>

<style>
.slide-fade-enter-active {
  transition: all .5s ease;
}
.slide-fade-leave-active {
  transition: all .5s ease;
}
.slide-fade-enter, .slide-fade-leave-to
/* .slide-fade-leave-active below version 2.1.8 */ {
  transform: translateY(100px);
  opacity: 0;
}
</style>
