<template>
    <div class="flex items-center">
        <span class="mr-2 mt-1">{{$t('dashboard.from')}}</span>
        <input-small v-model="time_from" class="w-28" inputtype="time" inputname="name" inputid="name" inputplaceholder="00:00" :readonly="readonly"/>
        <span class="mx-2 mt-1">{{$t('dashboard.to')}}</span>
        <input-small v-model="time_to" class="w-28" inputtype="time" inputname="name" inputid="name" inputplaceholder="00:00" :readonly="readonly"/>
        <a @click="$emit('remove')" v-if="!readonly" class="ml-2 mt-1 hover:text-primary cursor-pointer" ><icon-x class="h-6"/></a>
        <button-l @click.native="$emit('addTime', openingHour.day)" v-if="!readonly" settings="ml-4 hover:text-primary cursor-pointer">{{$t('dashboard.add_time')}}</button-l>
    </div>
</template>

<script>
import IconX from '../icons/IconX.vue'
import ButtonL from './ButtonL.vue'
import InputSmall from './InputSmall.vue'
export default {
    components: { InputSmall, IconX, ButtonL },
    name: 'TimePeriod',
    props: {
        openingHour: {
            type: Object
        },
        readonly: {
            type: Boolean
        }
    },
    computed: {
        time_from: {
            set (val) {
                var times = val.split(':')
                this.openingHour.from[0] = times[0]
                this.openingHour.from[1] = times[1]
            },
            get () {
                var a = this.openingHour.from[0]
                var b = this.openingHour.from[1]
                var final = (a + ':' + b)
                return final
            }
        },
        time_to: {
            set (val) {
                var times = val.split(':')
                this.openingHour.to[0] = times[0]
                this.openingHour.to[1] = times[1]
            },
            get () {
                var a = this.openingHour.to[0]
                var b = this.openingHour.to[1]
                var final = (a + ':' + b)
                return final
            }
        }
    }
}
</script>
