<template>
  <div class="metafield-create">
    <div class="relative mb-4">
      <input-normal inputtype="text" v-model="local.name">
        <template #title>{{ $t('dashboard.metafields_label_name') }}</template>
      </input-normal>
    </div>
    <div class="relative mb-4">
      <input-normal inputtype="text" v-model="local.key">
        <template #title>{{ $t('dashboard.metafields_label_key') }}</template>
      </input-normal>
      <span class="text-xs text-gray-500">{{ $t('dashboard.metafields_label_key_hint') }}</span>
    </div>
    <div class="relative mb-4">
      <input-normal inputtype="text" v-model="local.description">
        <template #title>{{ $t('dashboard.metafields_label_description') }}</template>
      </input-normal>
      <span class="text-xs text-gray-500 absolute top-1/2 right-3">{{beschreibung.length}}/100</span>
    </div>
    <div class="relative mb-4">
      <label for="name" class="block text-sm font-bold">{{ $t('dashboard.metafields_label_quantity') }}</label>
      <div class="relative mt-1">
        <multiselect
          v-model="local.quantity"
          placeholder="Select one"
          :options="anzahlOptions"
          :searchable="false"
          :allow-empty="false"
        />
      </div>
    </div>
    <div class="relative mb-4">
      <label for="name" class="block text-sm font-bold">{{ $t('dashboard.metafields_label_content_type') }}</label>
      <div class="relative mt-1">
        <multiselect
          v-model="contentTypeValue"
          track-by="name"
          label="name"
          placeholder="Select one"
          @select="onSelectContentTypeValue"
          :options="contentTypeOptions"
          :searchable="false"
          :allow-empty="false"
        />
      </div>
    </div>
    <div v-if="contentTypeValue && contentTypeValue.options" class="relative mb-4">
      <label for="name" class="block text-sm font-bold">{{contentTypeValue.name}} Options</label>
      <ul class="mt-1 rounded border-solid border border-gray-200">
        <li v-for="option of contentTypeValue.options" :key="option.id" class="p-4 border-b border-gray-200">
          <input-radio :label="option.label" :id="option.id" :value="option.value" :name="option.name" :checked="option.isSelected" @change="handleInputRadioChange" />
        </li>
      </ul>
    </div>
    <div v-if="validation">
      <h5 class="mb-3 text-gray-500">{{ $t("dashboard.metafields_validation") }}</h5>
      <p class="text-xs mb-4">{{validation.hint}}</p>
      <hr />
      <h5 class="mt-3 mb-3 text-sm text-gray-500">{{ $t("dashboard.metafields_label_additional_rules") }}</h5>
      <div v-if="validation.id.indexOf('number') !== -1">
        <input-normal class="mb-4" inputtype="number" v-model="local.minNumber">
          <template #title>{{ $t("dashboard.metafields_label_minimum_value") }}</template>
        </input-normal>
        <input-normal inputtype="number" v-model="local.maxNumber">
          <template #title>{{ $t("dashboard.metafields_label_maximum_value") }}</template>
        </input-normal>
      </div>
      <div v-if="validation.id.indexOf('text') !== -1">
        <input-normal class="mb-4" inputtype="text" v-model="local.regexp">
          <template #title>{{ $t("dashboard.metafields_label_regex") }}</template>
        </input-normal>
        <input-normal class="mb-4" inputtype="number" v-model="local.minLength">
          <template #title>{{ $t("dashboard.metafields_label_minimum_length") }}</template>
        </input-normal>
        <input-normal inputtype="number" v-model="local.maxLength">
          <template #title>{{ $t("dashboard.metafields_label_maximum_length") }}</template>
        </input-normal>
      </div>
      <div v-if="validation.id.indexOf('measurement.gewicht') !== -1">
        <div class="input-select-box">
          <input-normal class="mb-4" inputtype="text" v-model="local.minMeasurement">
            <template #title>{{ $t("dashboard.metafields_label_minimum_weight") }}</template>
          </input-normal>
          <select name="gewicht" id="gewicht-select" v-model="local.measurementUnit" class="focus:ring-primary focus:border-primary block w-full p-3.5 border-gray-200 rounded-md placeholder-gray-300">
            <option v-for="option of gewichtOptions" :value="option.value" :key="option.name">{{option.name}}</option>
          </select>
        </div>
        <div class="input-select-box">
          <input-normal class="mb-4" inputtype="text" v-model="local.maxMeasurement">
            <template #title>{{ $t("dashboard.metafields_label_minimum_weight") }}</template>
          </input-normal>
          <select name="gewicht" id="gewicht-select" v-model="local.measurementUnit" class="focus:ring-primary focus:border-primary block w-full p-3.5 border-gray-200 rounded-md placeholder-gray-300">
            <option v-for="option of gewichtOptions" :value="option.value" :key="option.name">{{option.name}}</option>
          </select>
        </div>
      </div>
      <div v-if="validation.id.indexOf('measurement.volumen') !== -1">
        <div class="input-select-box">
          <input-normal class="mb-4" inputtype="text" v-model="local.minMeasurement">
            <template #title>{{ $t("dashboard.metafields_label_minimum_volume") }}</template>
          </input-normal>
          <select name="gewicht" id="gewicht-select" v-model="local.measurementUnit" class="focus:ring-primary focus:border-primary block w-full p-3.5 border-gray-200 rounded-md placeholder-gray-300">
            <option v-for="option of volumenOptions" :value="option.value" :key="option.name">{{option.name}}</option>
          </select>
        </div>
        <div class="input-select-box">
          <input-normal class="mb-4" inputtype="text" v-model="local.maxMeasurement">
            <template #title>{{ $t("dashboard.metafields_label_maximum_volume") }}</template>
          </input-normal>
          <select name="volumen" id="volumen-select" v-model="local.measurementUnit" class="focus:ring-primary focus:border-primary block w-full p-3.5 border-gray-200 rounded-md placeholder-gray-300">
            <option v-for="option of volumenOptions" :value="option.value" :key="option.name">{{option.name}}</option>
          </select>
        </div>
      </div>
      <div v-if="validation.id.indexOf('measurement.maß') !== -1">
        <div class="input-select-box">
          <input-normal class="mb-4" inputtype="text" v-model="local.minMeasurement">
            <template #title>{{ $t("dashboard.metafields_label_minimum_dimension") }}</template>
          </input-normal>
          <select name="gewicht" id="gewicht-select" v-model="local.measurementUnit" class="focus:ring-primary focus:border-primary block w-full p-3.5 border-gray-200 rounded-md placeholder-gray-300">
            <option v-for="option of maßOptions" :value="option.value" :key="option.name">{{option.name}}</option>
          </select>
        </div>
        <div class="input-select-box">
          <input-normal class="mb-4" inputtype="text" v-model="local.maxMeasurement">
            <template #title>{{ $t("dashboard.metafields_label_maximum_dimension") }}</template>
          </input-normal>
          <select name="maß" id="maß-select" v-model="local.measurementUnit" class="focus:ring-primary focus:border-primary block w-full p-3.5 border-gray-200 rounded-md placeholder-gray-300">
            <option v-for="option of maßOptions" :value="option.value" :key="option.name">{{option.name}}</option>
          </select>
        </div>
      </div>
      <div v-if="validation.id.indexOf('url') !== -1">
        <p class="text-sm text-gray-500 mb-2">{{ $t("dashboard.metafields_label_restrict_url") }}</p>
        <div class="focus:ring-primary focus:border-primary w-full p-3.5 border-gray-200 border rounded-md mb-1">
          <ul v-if="domainsToAllow.length > 0">
            <li v-for="(domain, index) in domainsToAllow" :key="index" class="bg-gray-300 py-1 px-2 pr-1 rounded inline-block mr-2 mb-2">
              <span class="text-xs inline-block align-middle">{{domain}}</span>
              <button class="hover:text-primary focus:outline-none min-h-2 inline-block align-middle ml-2" type="button" @click="removeDomain(domain)" aria-label="Remove Tag">
                <icon-x class="h-5"/>
              </button>
            </li>
          </ul>
          <input type="text" v-model="domainToAllow" class="border-none p-0 outline-none w-full focus:outline-none focus:ring-0" @keyup.enter="addDomainToAllow">
        </div>
        <p class="text-xs">{{ $t("dashboard.metafields_label_restrict_url_hint") }}</p>
      </div>
      <div v-if="validation.id.indexOf('timestamp.datum') !== -1">
        <div class="relative mb-4">
          <label for="mindestdatum" class="block text-sm mb-1">{{ $t("dashboard.metafields_label_minimum_date") }}</label>
          <datetime v-model="local.minTimestamp" input-id="mindestdatum" class="theme-primary" />
          <icon-calendar class="absolute top-1/2 left-2" />
        </div>
        <div class="relative">
          <label for="maximaldatum" class="block text-sm mb-1">{{ $t("dashboard.metafields_label_maximum_date") }}</label>
          <datetime v-model="local.maxTimestamp" input-id="maximaldatum" class="theme-primary" />
          <icon-calendar class="absolute top-1/2 left-2" />
        </div>
      </div>
      <div v-if="validation.id.indexOf('timestamp.uhrzeit') !== -1">
        <div class="relative mb-4">
          <label for="mindestdatumZeit" class="block text-sm mb-1">{{ $t("dashboard.metafields_label_minimum_datetime") }}</label>
          <datetime v-model="local.minTimestamp" input-id="mindestdatumZeit" type="datetime" class="theme-primary" />
          <icon-calendar class="absolute top-1/2 left-2" />
        </div>
        <div class="relative">
          <label for="maximaldatumZeit" class="block text-sm mb-1">{{ $t("dashboard.metafields_label_maximum_datetime") }}</label>
          <datetime v-model="local.maxTimestamp" input-id="maximaldatumZeit" type="datetime" class="theme-primary" />
          <icon-calendar class="absolute top-1/2 left-2" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Multiselect from 'vue-multiselect'
import InputRadio from '../ui/InputRadio.vue'
import InputNormal from '../ui/InputNormal.vue'
import IconX from '../icons/IconX.vue'
import IconCalendar from '@/components/icons/IconCalendar.vue'
import { Datetime } from 'vue-datetime'
import { MetafieldDefinition } from 'delivery-boosting-2021-model'
export default {
    name: 'SettingsMetafieldForm',
    components: {
        Multiselect,
        InputRadio,
        InputNormal,
        IconX,
        Datetime,
        IconCalendar
    },
    props: ['value'],
    data () {
        const name = this.initialData ? this.initialData.definitionsname.name : null
        const contentTypeValue = this.initialData ? this.initialData.inhaltsart : null
        const namensraumSchlüssel = this.initialData ? this.initialData.definitionsname.namensraumSchlüssel : null
        const selectedContentTypeOption = this.initialData ? this.initialData.inhaltsart.options.find(option => option.isSelected) : null
        return {
            metafieldDefinitonList: [],
            name,
            namensraumSchlüssel,
            beschreibung: '',
            contentTypeValue,
            selectedContentTypeOption,
            domainToAllow: null,
            selectedGewichtOption: 'KILOGRAMS',
            domainsToAllow: [],
            mindestdatum: null,
            maximaldatum: null,
            mindestdatumZeit: null,
            maximaldatumZeit: null,
            anzahlOptions: ['Einzelner Wert', 'Mehrere Werte'],
            contentTypeOptions: [
                {
                    name: 'Text',
                    value: 'text',
                    options: [
                        {
                            id: 'einzeiliger-text',
                            label: 'Einzeiliger Text',
                            name: 'text',
                            value: 'einzeiliger-text',
                            isSelected: false,
                            validation: {
                                id: 'text.einzeiliger-text',
                                hint: 'Werte können Buchstaben, Zahlen und Sonderzeichen enthalten.'
                            }
                        },
                        {
                            id: 'mehrzeiliger-text',
                            label: 'Mehrzeiliger Text',
                            name: 'text',
                            value: 'mehrzeiliger-text',
                            isSelected: false,
                            validation: {
                                id: 'text.mehrzeiliger-text',
                                hint: 'Werte können Buchstaben, Zahlen und Sonderzeichen enthalten.'
                            }
                        }
                    ]
                },
                {
                    name: 'URL',
                    value: 'url',
                    validation: {
                        id: 'url',
                        hint: 'Werte müssen HTTPS-, HTTP- oder mailto:-Links sein.'
                    }
                },
                {
                    name: 'Color (Hex)',
                    value: 'color'
                },
                {
                    name: 'Measurement',
                    value: 'measurement',
                    options: [
                        {
                            id: 'gewicht',
                            label: 'Gewicht',
                            name: 'measurement',
                            value: 'gewicht',
                            isSelected: false,
                            validation: {
                                id: 'measurement.gewicht',
                                hint: 'Werte müssen Zahlen sein.'
                            }
                        },
                        {
                            id: 'volumen',
                            label: 'Volumen',
                            name: 'measurement',
                            value: 'volumen',
                            isSelected: false,
                            validation: {
                                id: 'measurement.volumen',
                                hint: 'Werte müssen Zahlen sein.'
                            }
                        },
                        {
                            id: 'maß',
                            label: 'Maß',
                            name: 'measurement',
                            value: 'maß',
                            isSelected: false,
                            validation: {
                                id: 'measurement.maß',
                                hint: 'Werte müssen Zahlen sein.'
                            }
                        }
                    ]
                },
                {
                    name: 'Boolean (true/false)',
                    value: 'boolean'
                },
                {
                    name: 'Number',
                    value: 'number',
                    options: [
                        {
                            id: 'ganze-zahl',
                            label: 'Ganze Zahl',
                            name: 'number',
                            value: 'ganze-zahl',
                            isSelected: false,
                            validation: {
                                id: 'number.ganze-zahl',
                                hint: 'Werte müssen Zahlen ohne Dezimalstelle sein.'
                            }
                        },
                        {
                            id: 'dezimal',
                            label: 'Dezimal',
                            name: 'number',
                            value: 'dezimal',
                            isSelected: false,
                            validation: {
                                id: 'number.dezimal',
                                hint: 'Werte müssen Zahlen mit einer Dezimalstelle sein.'
                            }
                        }
                    ]
                },
                {
                    name: 'Timestamp (Date)',
                    value: 'timestamp',
                    options: [
                        {
                            id: 'datum',
                            label: 'Datum',
                            name: 'timestamp',
                            value: 'datum',
                            isSelected: false,
                            validation: {
                                id: 'timestamp.datum',
                                hint: 'Werte müssen Daten sein.'
                            }
                        },
                        {
                            id: 'uhrzeit',
                            label: 'Uhrzeit',
                            name: 'timestamp',
                            value: 'uhrzeit',
                            isSelected: false,
                            validation: {
                                id: 'timestamp.uhrzeit',
                                hint: 'Werte müssen eine Datums- und Zeitangabe beinhalten.'
                            }
                        }
                    ]
                }
            ],
            gewichtOptions: [
                {
                    name: 'kg',
                    value: 'KILOGRAMS'
                },
                {
                    name: 'g',
                    value: 'GRAMS'
                },
                {
                    name: 'lb',
                    value: 'POUNDS'
                },
                {
                    name: 'oz',
                    value: 'OUNCES'
                }
            ],
            volumenOptions: [
                {
                    name: 'ml',
                    value: 'MILLILITERS'
                },
                {
                    name: 'cl',
                    value: 'CENTILITERS'
                },
                {
                    name: 'L',
                    value: 'LITERS'
                },
                {
                    name: 'm³',
                    value: 'CUBIC_METERS'
                },
                {
                    name: 'fl oz',
                    value: 'FLUID_OUNCES'
                },
                {
                    name: 'pt',
                    value: 'PINTS'
                },
                {
                    name: 'qt',
                    value: 'QUARTS'
                },
                {
                    name: 'gal',
                    value: 'GALLONS'
                },
                {
                    name: 'imp fl oz',
                    value: 'IMPERIAL_FLUID_OUNCES'
                },
                {
                    name: 'imp pt',
                    value: 'IMPERIAL_PINTS'
                },
                {
                    name: 'imp qt',
                    value: 'IMPERIAL_QUARTS'
                },
                {
                    name: 'imp gal',
                    value: 'IMPERIAL_GALLONS'
                }
            ],
            maßOptions: [
                {
                    name: 'mm',
                    value: 'MILLIMETERS'
                },
                {
                    name: 'cm',
                    value: 'CENTIMETERS'
                },
                {
                    name: 'in',
                    value: 'INCHES'
                },
                {
                    name: 'ft',
                    value: 'FEET'
                },
                {
                    name: 'yd',
                    value: 'YARDS'
                }
            ]
        }
    },
    created: function () {
        if (this.value && this.value.fieldType) {
            this.contentTypeValue = this.contentTypeOptions.find(item => item.value === this.value.fieldType)

            if (this.contentTypeValue.options) {
                this.contentTypeValue.options.forEach(item => {
                    if (item.value === this.value.textType) {
                        this.selectedContentTypeOption = item
                        item.isSelected = true
                    }
                    if (item.value === this.value.numberType) {
                        this.selectedContentTypeOption = item
                        item.isSelected = true
                    }
                    if (item.value === this.value.measurementType) {
                        this.selectedContentTypeOption = item
                        item.isSelected = true
                    }
                    if (item.value === this.value.timestampType) {
                        this.selectedContentTypeOption = item
                        item.isSelected = true
                    }
                })
            }
        }
    },
    watch: {
        restaurant: {
            immediate: true,
            async handler () {
                try {
                    console.log('this.$store.state.restaurant', this.$store.state.restaurant)
                    this.metafieldDefinitonList = await this.$store.state.restaurant.getMetafieldDefinitionList()
                } catch (error) {
                    console.warn(error)
                }
            }
        },
        domainsToAllow: {
            handler (val) {
                this.local.restrictedUrl = val.join(', ')
            }
        },
        selectedContentTypeOption: {
            handler (params) {
                if (params === null) {
                    this.local.textType = ''
                    this.local.numberType = ''
                    this.local.measurementType = ''
                    this.local.timestampType = ''
                    return
                }
                this.setLocalChildFieldType(this.local.fieldType, params.value)
            }
        },
        local: {
            handler (val) {
                if (this.selectedContentTypeOption === null) {
                    this.local.textType = ''
                    this.local.numberType = ''
                    this.local.measurementType = ''
                    this.local.timestampType = ''
                    return
                }
                this.setLocalChildFieldType(val.fieldType, this.selectedContentTypeOption.value)
            },
            deep: true
        }
    },
    computed: {
        validation () {
            if (this.selectedContentTypeOption) {
                return this.selectedContentTypeOption.validation
            } else {
                if (this.contentTypeValue && this.contentTypeValue.validation) {
                    return this.contentTypeValue.validation
                }
            }

            return null
        },
        local () {
            return this.value ? this.value : {
                name: '',
                key: '',
                description: '',
                type: '',
                fieldType: '',
                quantity: '',
                textType: '',
                regexp: '',
                minLength: '',
                maxLength: '',
                restrictedUrl: '',
                measurementType: '',
                measurementUnit: '',
                minMeasurement: '',
                maxMeasurement: '',
                numberType: '',
                minNumber: '',
                maxNumber: '',
                timestampType: '',
                minTimestamp: '',
                maxTimestamp: ''
            }
        }
    },
    methods: {
        async addMetafieldDefinition () {
            await this.metafieldDefinitonList.addMetafieldDefinition(new MetafieldDefinition(this.metafieldDefinition))
        },
        showModal () {
            this.isModalVisible = true
        },
        closeModal () {
            this.isModalVisible = false
        },
        handleInputRadioChange (event) {
            this.contentTypeValue.options.forEach(item => {
                if (item.id === event) {
                    this.selectedContentTypeOption = item
                }
            })
        },
        removeDomain (domain) {
            const index = this.domainsToAllow.indexOf(domain)
            if (index > -1) {
                this.domainsToAllow.splice(index, 1)
            }
        },
        addDomainToAllow () {
            this.domainsToAllow.push(this.domainToAllow)
            this.domainToAllow = null
        },
        onSelectContentTypeValue (params) {
            if (params.options) {
                params.options[0].isSelected = true
                this.selectedContentTypeOption = params.options[0]
            } else {
                this.selectedContentTypeOption = null
            }
            this.local.fieldType = params.value
        },
        setLocalChildFieldType (parentField, payload) {
            switch (parentField) {
            case 'text': {
                this.local.textType = payload
                this.local.numberType = ''
                this.local.measurementType = ''
                this.local.timestampType = ''
                break
            }
            case 'number': {
                this.local.textType = ''
                this.local.numberType = payload
                this.local.measurementType = ''
                this.local.timestampType = ''
                break
            }
            case 'measurement': {
                this.local.textType = ''
                this.local.numberType = ''
                this.local.measurementType = payload
                this.local.timestampType = ''
                break
            }
            case 'timestamp': {
                this.local.textType = ''
                this.local.numberType = ''
                this.local.measurementType = ''
                this.local.timestampType = payload
                break
            }
            }
        }
    }
}
</script>

<style lang="postcss" scoped>
  ul > li:last-child {
    border: none;
  }
  .input-select-box {
    display: grid;
    grid-gap: 10px;
    grid-template-columns: 1fr 100px;
    align-items: center;
  }
  .input-select-box select {
    height: 55px;
    margin-top: 6px;
  }
  .metafield-create >>> .vdatetime.theme-primary input {
    padding-left: 40px;
  }
</style>
