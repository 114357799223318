<template>
    <div>
        <label :for="inputname" class="block text-sm font-bold"><slot name="title"/></label>
        <div class="mt-1 relative">
            <!--
            <input v-bind:value="value/100" v-on:input="$emit('input', $event.target.value*100)" type="number" :name="inputname" :id="inputid" placeholder="0.00" class="focus:ring-primary focus:border-primary block w-full p-3.5 border-gray-200 rounded-md">
            -->
              <currency-input
                v-model="innerValue"
                :currency="null"
                locale="de"
                class="focus:ring-primary focus:border-primary block w-full p-3.5 border-gray-200 border-2 rounded-md outline-none"
            />
            <div class="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                <span class="text-gray-500 sm:text-sm" id="price-currency">
                    €
                </span>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'InputNormal',
    props: ['value', 'inputtype', 'inputname', 'inputid'],
    data () {
        return {
            innerValue: this.value / 100 || 0
        }
    },
    watch: {
        innerValue () {
            this.$emit('input', this.innerValue * 100)
        }
    }
}
</script>
