<template>
    <modal @close="close">
        <template #title>{{ $t('dashboard.new_location') }}</template>
        <template #interactive><button-b :loading="loading" @click.native="addAndSave" class="bg-primary">{{ $t('dashboard.save') }}</button-b></template>
        <template #body>
            <tabs>
                <!-- Tab "Allgemein" -->
                <tab :title="$t('dashboard.generalo')">
                    <div class="grid grid-cols-6 gap-6 mt-10">
                        <!---
                        <div class="col-span-6">
                            <label for="name" class="block text-sm font-bold mb-1">{{ $t('dashboard.status') }}</label>
                            <multiselect
                            v-model="valueStatus"
                            :options="[ 'Offen', 'Geschlossen' ]"
                            :placeholder="$t('dashboard.choose_status')"
                            :deselectLabel="$t('dashboard.remove')"
                            :selectedLabel="$t('dashboard.selected')"
                            :selectLabel="$t('dashboard.select')">
                            <template slot="noResult">{{ $t('dashboard.no_results') }}!</template>
                            </multiselect>
                        </div> --->
                        <div class="col-span-6">
                            <input-normal v-model="location.general.title" inputtype="text" inputname="name" inputid="name" :inputplaceholder="$t('dashboard.example_city')">
                            <template #title>{{ $t('dashboard.title') }}</template>
                            </input-normal>
                        </div>
                        <!--<div class="col-span-6 sm:col-span-3">
                            <input-normal v-model="location.general.minOrderValue" inputtype="text" inputname="name" inputid="name" :inputplaceholder="$t('dashboard.money_placeholder')">
                            <template #title>{{ $t('dashboard.minOrderValue') }}</template>
                            </input-normal>
                        </div>-->
                        <div class="col-span-6 sm:col-span-4">
                            <input-normal v-model="location.general.city" inputtype="text" inputname="name" inputid="name" :inputplaceholder="$t('dashboard.example_city')">
                            <template #title>{{ $t('dashboard.city') }}</template>
                            </input-normal>
                        </div>
                        <div class="col-span-6 sm:col-span-2">
                            <input-normal v-model="location.general.zip" inputtype="text" inputname="name" inputid="name" :inputplaceholder="$t('dashboard.example_zip')">
                            <template #title>{{ $t('dashboard.postal_code') }}</template>
                            </input-normal>
                        </div>
                        <div class="col-span-6 sm:col-span-3">
                            <input-normal v-model="location.general.street" inputtype="text" inputname="name" inputid="name" :inputplaceholder="$t('dashboard.example_street')">
                            <template #title>{{ $t('dashboard.street') }}</template>
                            </input-normal>
                        </div>
                        <div class="col-span-6 sm:col-span-3">
                            <input-normal v-model="location.general.streetnum" inputtype="text" inputname="name" inputid="name" :inputplaceholder="$t('dashboard.example_number')">
                            <template #title>{{ $t('dashboard.streetnumber') }}</template>
                            </input-normal>
                        </div>
                        <div class="col-span-6 sm:col-span-6">
                            <upload-drag-and-drop :value="media" @input="i => media = i">
                            <template #title>{{ $t('dashboard.bck_img') }}</template>
                            </upload-drag-and-drop>
                        </div>
                        <checkbox v-model="location.active" checkboxname="Aktiv"><span class="text-base">{{ $t('dashboard.active') }}</span></checkbox>
                    </div>
                </tab><!-- END: Tab "Allgemein" -->
                <!-- Tab "Öffnungszeiten" -->
                <tab title="Öffnungszeiten">
                    <location-time v-for="(openingHour, index) in openingHours" :index="index" :openingHour="openingHour" :key="index" @addTime="addTimetoDay(openingHour.day)" @remove="removeOpeningHour(index)"/>
                    <div class="flex mt-10 justify-between">
                        <button-l @click.native="addOpeningHour" settings="text-primary line cursor-pointer">{{ $t('dashboard.add_tag') }}</button-l>
                        <button-l @click.native="cloneOpeningHour(location.openingHours[location.openingHours.length-1])" settings="hover:text-primary flex cursor-pointer">
                            <svg xmlns="http://www.w3.org/2000/svg" class="h-6 mr-1" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M8 16H6a2 2 0 01-2-2V6a2 2 0 012-2h8a2 2 0 012 2v2m-6 12h8a2 2 0 002-2v-8a2 2 0 00-2-2h-8a2 2 0 00-2 2v8a2 2 0 002 2z" />
                            </svg>
                            {{ $t('dashboard.clone_tag_above') }}
                        </button-l>
                    </div>
                </tab><!-- END: Tab "Öffnungszeiten" -->
            </tabs>
        </template>
    </modal>
</template>

<script>
import InputNormal from '@/components/ui/InputNormal.vue'
import ButtonB from '@/components/ui/ButtonB.vue'
import Modal from '@/components/dashboard/Modal.vue'
import Tabs from '@/components/ui/Tabs.vue'
import Tab from '@/components/ui/Tab.vue'
import ButtonL from '@/components/ui/ButtonL.vue'
// import Multiselect from 'vue-multiselect'
import LocationTime from '@/components/dashboard/LocationTime.vue'
import { Location } from 'delivery-boosting-2021-model'
import UploadDragAndDrop from '../../components/ui/UploadDragAndDrop.vue'
import Checkbox from '@/components/ui/Checkbox.vue'

export default {
    name: 'DashboardSettings',
    data () {
        return {
            title: '',
            city: '',
            zip: '',
            street: '',
            streetnum: '',
            minOrderValue: '',
            valueStatus: null,
            loading: false,
            openingHours: []
        }
    },
    props: {
        location: {
            type: Location
        }
    },
    computed: {
        media: {
            set (val) {
                this.location.media = [val.toPlainObject()].filter(m => m.url)
            },
            get () {
                return this.location.media[0]
            }
        }
    },
    created () {
        this.openingHours = this.location.openingHours.sort(({ day: x, from: [a, b] }, { day: y, from: [c, d] }) => (parseInt(x) * 10000 + (parseInt(a) * 60 + parseInt(b))) - (parseInt(y) * 10000 + (parseInt(c) * 60 + parseInt(d))))
    },
    methods: {
        async addAndSave () {
            this.loading = true
            await this.$store.state.restaurant.commit()
            this.loading = false
            this.close()
        },
        close () {
            this.$emit('close')
        },
        addOpeningHour () {
            this.location.addOpeningHours()
        },
        addTimetoDay (day) {
            this.location.addOpeningHours(day)
        },
        cloneOpeningHour (val) {
            this.location.addOpeningHours(val.day, val.from[0], val.from[1], val.to[0], val.to[1])
        },
        removeOpeningHour (ind) {
            this.location.removeOpeningHours(ind)
        }
    },
    components: {
        InputNormal,
        ButtonB,
        Modal,
        Tabs,
        Tab,
        ButtonL,
        // Multiselect,
        LocationTime,
        UploadDragAndDrop,
        Checkbox
    }
}
</script>

<style src="@/assets/css/multiselect.css"></style>
