<template>
    <modal @close="close">
        <template #title>{{ $t('dashboard.popup_delete_location') }}<span class="text-primary">{{location.general.title}}</span>{{ $t('dashboard.popup_delete_category2') }}</template>
        <template #body>
            <button-b :loading="loading" @click.native="confirmDeletion" class="bg-primary">{{ $t('dashboard.delete') }}</button-b>
            <button-b @click.native="close" class="bg-gray-600 ml-2" style="float: center">{{ $t('dashboard.cancel') }}</button-b>
        </template>
    </modal>
</template>

<script>
import ButtonB from '../../components/ui/ButtonB.vue'
import Modal from '../../components/dashboard/Modal.vue'
import { Location } from 'delivery-boosting-2021-model'

export default {
    data () {
        return {
            valueStatus: null,
            loading: false
        }
    },
    props: {
        location: {
            type: Location
        }
    },
    methods: {
        close () {
            this.$emit('close')
        },
        async confirmDeletion () {
            this.loading = true
            this.$store.state.restaurant.removeLocation(this.location.uuid)
            await this.$store.state.restaurant.commit()
            this.loading = false
            this.close()
        }
    },
    components: {
        ButtonB,
        Modal

    }
}
</script>
