<template>
    <modal @close="close">
        <template #title>{{ metafieldDefinition.name }}</template>
        <template #interactive><button-b @click.native="saveAndClose" class="bg-primary rounded text-white text-sm">{{ $t('dashboard.add') }}</button-b></template>
        <template #body>
          <settings-metafield-form v-model="metafieldDefinition" />
        </template>
      </modal>
</template>

<script>
import Modal from '@/components/dashboard/Modal.vue'
import ButtonB from '@/components/ui/ButtonB.vue'
import SettingsMetafieldForm from '@/components/dashboard/SettingsMetafieldForm.vue'
import { MetafieldDefinition } from 'delivery-boosting-2021-model'

export default {
    name: 'EditMetafield',
    components: {
        Modal,
        ButtonB,
        SettingsMetafieldForm
    },
    props: {
        metafieldDefinition: {
            type: Object,
            required: true
        }
    },
    computed: {
        restaurant () {
            return this.$store.state.restaurant
        }
    },
    methods: {
        async saveAndClose () {
            const metafieldDefinitionList = await this.restaurant.getMetafieldDefinitionList()
            await metafieldDefinitionList.updateMetafieldDefinition(new MetafieldDefinition(this.metafieldDefinition))
            this.close()
        },
        close () {
            this.$emit('close')
        }
    }

}
</script>
