<template>
  <dashboard-sidebar v-if="mounted">
    <template #over-content>
      <!-- old version
    <div id="divid" style="display:none" class="bg-green btn inline-flex items-center font-bold rounded-md shadow-sm text-white focus:outline-none cursor-default w-full justify-center">
          <strong class="font-bold text-lg">{{ $t('dashboard.safe_settings') }}</strong>
    </div>
    <div id="fancy" style="display:none; position:relative; top:-6px; height: 0.36rem" class="bg-green-dark w-full grid mb-5 rounded-md"></div>
    -->
      <!-- This example requires Tailwind CSS v2.0+ -->
      <!-- Global notification live region, render this permanently at the end of the document -->
      <div
        aria-live="assertive"
        class="
          fixed
          inset-0
          flex
          items-end
          px-4
          py-6
          pointer-events-none
          sm:p-6
          sm:items-start
          z-25
        "
      >
      <transition enter-active-class="transform ease-out duration-300 transition opacity-100" enter-from-class="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2" enter-to-class="translate-y-0 opacity-100 sm:translate-x-0" leave-active-class="transition ease-in duration-500" leave-from-class="opacity-100" leave-to-class="opacity-0">
        <div v-if="isNotificationVisible" class="w-full flex flex-col items-center space-y-4 sm:items-end">
                <!--
            Notification panel, dynamically insert this into the live region when it needs to be displayed

            Entering: "transform ease-out duration-300 transition"
              From: "translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
              To: "translate-y-0 opacity-100 sm:translate-x-0"
            Leaving: "transition ease-in duration-100"
              From: "opacity-100"
              To: "opacity-0"
            -->
          <div
            class="
              max-w-sm
              w-full
              bg-white
              shadow-lg
              rounded-lg
              pointer-events-auto
              ring-1 ring-black ring-opacity-5
              overflow-hidden
            "
          >
            <div class="p-4">
              <div class="flex items-start">
                <div class="flex-shrink-0">
                  <!-- Heroicon name: outline/check-circle -->
                  <svg
                    class="h-6 w-6 text-primary"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    aria-hidden="true"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"
                    />
                  </svg>
                </div>
                <div class="ml-3 w-0 flex-1 pt-0.5">
                  <p class="text-sm font-medium text-gray-900">
                    {{ $t('dashboard.safe_settings') }}
                  </p>
                  <p v-show="false" class="mt-1 text-sm text-gray-500"></p>
                </div>
                <div class="ml-4 flex-shrink-0 flex">
                  <button
                    @click="closeNotification"
                    class="
                      bg-white
                      rounded-md
                      inline-flex
                      text-gray-400
                      hover:text-gray-500
                      focus:outline-none
                      focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500
                    "
                  >
                    <span class="sr-only">Close</span>
                    <!-- Heroicon name: solid/x -->
                    <svg
                      class="h-5 w-5"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                      aria-hidden="true"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                        clip-rule="evenodd"
                      />
                    </svg>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </transition>
      </div>

      <title-bar>
        <template #title>
          {{ $t('dashboard.settings') }}
        </template>
        <template #interactive>
          <div class="mt-4 flex md:mt-0 md:ml-4">
            <button-a
              @click.native="$router.push({ name: 'dashboard-overview' })"
              settings="bg-primary mr-4 cursor-pointer"
            >
              <icon-chevron-left height="24" />
              {{ $t('dashboard.back') }}
            </button-a>
            <button-b :loading="loading" @click.native="save" class="bg-primary cursor-pointer">
              <icon-check class="inline-block" height="24" />
              {{ $t('dashboard.save') }}
            </button-b>
          </div>
        </template>
      </title-bar>
    </template>
    <template #content>
      <card-box>
        <settings-title-bar>
          <menu-tab :title="$t('dashboard.general')">
            <h4 class="mb-5">{{ $t('dashboard.general') }}</h4>
            <div class="grid grid-rows-1 grid-flow-col gap-5">
              <input-normal
                v-model="restaurant.generalTitle"
                inputtype="text"
                inputname="name"
                inputid="name1"
                :inputplaceholder="$t('dashboard.title')"
              >
                <template #title>{{ $t('dashboard.title') }}</template>
              </input-normal>
              <input-textarea
                v-model="restaurant.generalDescription"
                :inputplaceholder="$t('dashboard.example_descrest')"
              >
                <template #title>{{ $t('dashboard.description') }}</template>
              </input-textarea>
              <div class="row-span-3">
                <upload-drag-and-drop
                  :value="general"
                  @input="(i) => (general = i)"
                >
                  <template #title>{{ $t('dashboard.logo') }}</template>
                </upload-drag-and-drop>
              </div>
            </div>
            <div>
              <h4 class="mb-5 mt-5">{{ 'Produkte' }}</h4>
              <checkbox class="mb-4" v-model="restaurant.allowMultipleSKUs" checkboxname="SKU"><span class="text-base">{{ 'Identische Artikelnummern für Produkte zulassen' }}</span></checkbox>
            </div>
            <div>
              <h4 class="mb-5 mt-5">{{ 'Workflow' }}</h4>
              <checkbox class="mb-4" v-model="restaurant.shortWorkflow" checkboxname="shortWorkflow"><span class="text-base">{{ $t('dashboard.shortWorkflowDesc') }}</span></checkbox>
            </div>
            <h4 class="mb-5 mt-5">{{ $t('dashboard.colors') }}</h4>
            <div class="mt-2">
              <v-swatches
                v-model="restaurant.style.color"
                :swatches="swatches"
                shapes="circles"
                inline
                popover-x="left"
              >
              </v-swatches>
            </div>
            <div class="mt-0 flex">
              <div
                class="flex cursor-pointer text-primary"
                v-if="!picker && timeout"
                @click="showColorPicker"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="h-10 w-10 inline-block"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M12 6v6m0 0v6m0-6h6m-6 0H6"
                  />
                </svg>
                <span class="text-black mt-2 ml-2 font-semibold"
                  >{{ $t('dashboard.add_color') }}</span
                >
              </div>
              <transition name="slide-fade">
              <chrome
                v-if="picker"
                type="color"
                id="favcolor"
                name="favcolor"
                v-model="colorPicker"
              />
              </transition>
              <transition name="slide-fade">
              <div style="margin-top: 82px" v-if="picker">
                <div
                  @click="addColor"
                  class="cursor-pointer text-green flex items-center ml-1"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="h-11 w-11"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M12 6v6m0 0v6m0-6h6m-6 0H6"
                    />
                  </svg>
                </div>
                <div
                  @click="hideColorPicker"
                  class="cursor-pointer text-red flex items-center ml-2"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="h-9 w-9"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M6 18L18 6M6 6l12 12"
                    />
                  </svg>
                </div>
              </div>
              </transition>
            </div>
          </menu-tab>
          <menu-tab :title="$t('dashboard.header')">
            <h4 class="mb-5">{{ $t('dashboard.header') }}</h4>
            <div class="grid grid-rows-1 grid-flow-col gap-5">
              <input-normal
                v-model="restaurant.headerTitle"
                inputtype="text"
                inputname="name"
                inputid="name2"
                :inputplaceholder="$t('dashboard.restaurant_title')"
              >
                <template #title>{{ $t('dashboard.title') }}</template>
              </input-normal>
              <input-textarea
                v-model="restaurant.headerDescription"
                :inputplaceholder="$t('dashboard.example_descrest')"
              >
                <template #title>{{ $t('dashboard.description') }}</template>
              </input-textarea>
              <div class="row-span-3">
                <div class="cursor-pointer" @click="drawerVisible= true">
                <picture-placeholder
                  class="mb-1"
                  :value="header"
                  @input="i => header = i"
                >
                  <template #title>{{ $t('dashboard.bck_img') }}</template>
                </picture-placeholder>
                </div>
                <unsplash-search class="z-100" :title="'Restaurant'" :value="header" :drawerVisible="drawerVisible" :fullResolution="true" @hide="drawerVisible = false" @input="i => header = i"/>
              </div>
            </div>
          </menu-tab>
          <menu-tab :title="$t('dashboard.prices')">
            <h4 class="mb-5">{{ $t('dashboard.prices') }}</h4>
            <checkbox class="mb-4" v-model="restaurant.deliveryActive" checkboxname="delivery"><span class="text-base">{{ $t('dashboard.deliveryoff') }}</span></checkbox>
            <div v-if="!restaurant.deliveryActive">
              <p class="mb-2">
                <strong class="text-black">{{ $t('dashboard.delivery') }}</strong>
              </p>
              <div
                v-if="catalog.getMethodByUuid('delivery') && (typeof catalog.getMethodByUuid('delivery').priceAmount !== 'undefined')"
                class="mt-1 mb-2 grid grid-cols-3 gap-5"
              >
                <input-price
                  v-model="catalog.getMethodByUuid('delivery').priceAmount"
                  inputtype="text"
                  inputname="name"
                  inputid="name3"
                  :inputplaceholder="$t('dashboard.example_price')"
                >
                  <template #title>{{ $t('dashboard.delivery_price') }}</template>
                </input-price>
                <input-price
                  v-model="catalog.getMethodByUuid('delivery').minOrderValue"
                  inputtype="text"
                  inputname="name"
                  inputid="name4"
                  :inputplaceholder="$t('dashboard.example_minOrder')"
                >
                  <template #title>{{ $t('dashboard.minOrderValue') }}</template>
                </input-price>
                <input-price
                  v-model="catalog.getMethodByUuid('delivery').maxValue"
                  inputtype="text"
                  inputname="name"
                  inputid="name5"
                  :inputplaceholder="$t('dashboard.example_minOrder')"
                >
                  <template #title>{{ $t('dashboard.no_max_value') }}</template>
                </input-price>
              </div>
            </div>
            <p class="mb-2">
              <strong class="text-black">{{ $t('dashboard.pickup') }}</strong>
            </p>
            <div
              v-if="catalog.getMethodByUuid('pick-up') && (typeof catalog.getMethodByUuid('pick-up').priceAmount !== 'undefined')"
              class="mt-1 grid grid-cols-3 gap-5"
            >
              <input-price
                v-model="catalog.getMethodByUuid('pick-up').priceAmount"
                inputtype="text"
                inputname="name"
                inputid="name6"
                :inputplaceholder="$t('dashboard.example_price')"
              >
                <template #title>{{ $t('dashboard.pick-up_price') }}</template>
              </input-price>
              <input-price
                v-model="catalog.getMethodByUuid('pick-up').minOrderValue"
                inputtype="text"
                inputname="name"
                inputid="name7"
                :inputplaceholder="$t('dashboard.example_minOrder')"
              >
                <template #title>{{ $t('dashboard.minOrderValue') }}</template>
              </input-price>
              <input-price
                v-model="catalog.getMethodByUuid('pick-up').maxValue"
                inputtype="text"
                inputname="name"
                inputid="name8"
                :inputplaceholder="$t('dashboard.example_minOrder')"
              >
                <template #title>{{ $t('dashboard.no_max_value') }}</template>
              </input-price>
            </div>
            <h4 class="mb-5 mt-5">{{ $t('dashboard.paymentmethods') }}</h4>
            <p class="mb-2">
              <strong class="text-black">{{ $t('dashboard.pickup') }}</strong>
            </p>
            <multiselect
                v-model="catalog.paymentMethodsPickup"
                :options="paymentOptions"
                :placeholder="$t('dashboard.payment_placeholder')"
                :deselectLabel="$t('dashboard.remove')"
                :selectedLabel="$t('dashboard.selected')"
                :selectLabel="$t('dashboard.select')"
                :multiple="true"
              >
                <template slot="noResult">{{
                  $t("dashboard.no_results")
                }}</template>
                <template slot="singleLabel" slot-scope="{ option }"
                  ><strong>{{ $t(`dashboard.paymentMethods_${option}`) }}</strong></template
                >
                <template slot="option" slot-scope="{ option }">{{
                 $t(`dashboard.paymentMethods_${option}`)
                }}</template>
                <template slot="tag" slot-scope="{ option }">
                 <div class="multiselect__tags-wrap" style="" @click="catalog.paymentMethodsPickup = catalog.paymentMethodsPickup.filter(o => o !== option)"><span class="multiselect__tag"><span>{{$t(`dashboard.paymentMethods_${option}`)}}</span> <i aria-hidden="true" tabindex="1" class="multiselect__tag-icon"></i></span></div>
                </template>
            </multiselect>
            <div v-if="!restaurant.deliveryActive">
              <p class="mt-2 mb-2">
                <strong class="text-black">{{ $t('dashboard.delivery') }}</strong>
              </p>
              <multiselect
                  v-model="catalog.paymentMethodsDelivery"
                  :options="paymentOptions"
                  :placeholder="$t('dashboard.payment_placeholder')"
                  :deselectLabel="$t('dashboard.remove')"
                  :selectedLabel="$t('dashboard.selected')"
                  :selectLabel="$t('dashboard.select')"
                  :multiple="true"
                >
                  <template slot="noResult">{{
                    $t("dashboard.no_results")
                  }}</template>
                  <template slot="singleLabel" slot-scope="{ option }"
                    ><strong>{{ $t(`dashboard.paymentMethods_${option}`) }}</strong></template
                  >
                  <template slot="option" slot-scope="{ option }">{{
                  $t(`dashboard.paymentMethods_${option}`)
                  }}</template>
                  <template slot="tag" slot-scope="{ option }">
                  <div class="multiselect__tags-wrap" style="" @click="catalog.paymentMethodsDelivery = catalog.paymentMethodsDelivery.filter(o => o !== option)"><span class="multiselect__tag"><span>{{$t(`dashboard.paymentMethods_${option}`)}}</span> <i aria-hidden="true" tabindex="1" class="multiselect__tag-icon"></i></span></div>
                  </template>
              </multiselect>
            </div>
              <div v-if="paypalSettings && (catalog.paymentMethodsPickup.includes('paypal') || (!restaurant.deliveryActive && catalog.paymentMethodsDelivery.includes('paypal')))">
                <h4 class="mb-5 mt-5">{{ $t('dashboard.paypal') }}</h4>
                <input-normal
                v-model="paypalSettings.clientId"
                class="mt-2"
                inputtype="text"
                inputname="name"
                inputid="name9"
                inputplaceholder="abCD12345"
              >
                <template #title>{{ $t('dashboard.clientid') }}</template>
              </input-normal>
              <input-normal
              v-model="paypalSettings.clientSecret"
                class="mt-2"
                inputtype="text"
                inputname="name"
                inputid="name10"
                inputplaceholder="abCD12345"
              >
                <template #title>{{ $t('dashboard.clientsecret') }}</template>
              </input-normal>
              <div class="flex my-1">
                  <toggle class="mr-5 my-2" v-model="paypalSettings.live"><span class="font-bold ml-4">{{$t('dashboard.paypallive')}}</span></toggle>
              </div>
              </div>
          </menu-tab>
          <menu-tab :title="$t('dashboard.footer')">
            <h4 class="mb-5">{{ $t('dashboard.footer') }}</h4>
            <div class="grid grid-rows-1 grid-flow-col gap-5 mb-5 items-center">
              <input-normal
                v-model="restaurant.footerImp"
                inputtype="text"
                inputname="name"
                inputid="name11"
                :inputplaceholder="$t('dashboard.link')"
              >
                <template #title>{{ $t('dashboard.imp') }}</template>
              </input-normal>
              <input-normal
                v-model="restaurant.footerTel"
                inputtype="text"
                inputname="name"
                inputid="name12"
                :inputplaceholder="$t('dashboard.tel_example')"
              >
                <template #title>{{ $t('dashboard.tel') }}</template>
              </input-normal>
            </div>
            <div class="grid grid-rows-1 grid-flow-col gap-5 mb-5">
              <input-normal
                v-model="restaurant.footerDatasec"
                inputtype="text"
                inputname="name"
                inputid="name13"
                :inputplaceholder="$t('dashboard.link')"
              >
                <template #title>{{ $t('dashboard.datasec') }}</template>
              </input-normal>
              <input-normal
                v-model="restaurant.footerMail"
                inputtype="text"
                inputname="name"
                inputid="name14"
                :inputplaceholder="$t('dashboard.mail_example')"
              >
                <template #title>{{ $t('dashboard.mail') }}</template>
              </input-normal>
            </div>
            <div class="grid grid-rows-1 grid-flow-col gap-5 mb-5">
              <div class="w-1/2">
                <input-normal
                  v-model="restaurant.footerAgb"
                  inputtype="text"
                  inputname="name"
                  inputid="name15"
                  :inputplaceholder="$t('dashboard.link')"
                >
                  <template #title>{{ $t('dashboard.agb') }}</template>
                </input-normal>
              </div>
            </div>
          </menu-tab>
          <menu-tab :title="$t('dashboard.messages')">
            <h4 class="mb-5 text-black">
              {{ $t('dashboard.daily_messages') }}
            </h4>
            <div class="grid grid-rows-1 grid-flow-col gap-5 mb-5 mt-2">
              <input-textarea
                v-model="restaurant.topMessage"
                :inputplaceholder="$t('dashboard.top_placeholder')"
              >
                <template #title>{{ $t('dashboard.top_message') }}</template>
              </input-textarea>
              <input-textarea
                v-model="restaurant.bottomMessage"
                :inputplaceholder="$t('dashboard.bottom_placeholder')"
              >
                <template #title>{{ $t('dashboard.bottom_message') }}</template>
              </input-textarea>
            </div>
            <h4 class="mb-5 mt-10 text-black">
              {{ $t('dashboard.chat_messages') }}
            </h4>
            <label class="text-gray-600">{{
              $t('dashboard.info_chatmessages1')
            }}</label
            ><br />
            <label class="text-gray-600 mb-3">{{
              $t('dashboard.info_chatmessages2')
            }}</label>
            <div class="relative left-11">
              <li class="text-black mt-3">
                {{ "{{" }}<strong class="text-black">price</strong>}}
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  style="display: inline-block"
                  class="h-6 w-6 mr-1"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M13 7l5 5m0 0l-5 5m5-5H6"
                  /></svg
                >{{ $t('dashboard.var_price') }}
              </li>
              <li class="text-black">
                {{ "{{" }}<strong class="text-black">firstName</strong>}}
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  style="display: inline-block"
                  class="h-6 w-6 mr-1"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M13 7l5 5m0 0l-5 5m5-5H6"
                  /></svg
                >{{ $t('dashboard.var_firstName') }}
              </li>
              <li class="text-black">
                {{ "{{" }}<strong class="text-black">lastName</strong>}}
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  style="display: inline-block"
                  class="h-6 w-6 mr-1"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M13 7l5 5m0 0l-5 5m5-5H6"
                  /></svg
                >{{ $t('dashboard.var_lastName') }}
              </li>
              <li class="text-black">
                {{ "{{" }}<strong class="text-black">city</strong>}}
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  style="display: inline-block"
                  class="h-6 w-6 mr-1"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M13 7l5 5m0 0l-5 5m5-5H6"
                  /></svg
                >{{ $t('dashboard.var_city') }}
              </li>
              <li class="text-black">
                {{ "{{" }}<strong class="text-black">street</strong>}}
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  style="display: inline-block"
                  class="h-6 w-6 mr-1"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M13 7l5 5m0 0l-5 5m5-5H6"
                  /></svg
                >{{ $t('dashboard.var_street') }}
              </li>
            </div>
            <div class="grid grid-rows-1 grid-flow-col gap-5 mb-5 mt-2">
              <input-textarea
                v-model="restaurant.chatMessage6"
                :inputplaceholder="$t('dashboard.POSTED_placeholder')"
              >
                <template #title>{{ $t('dashboard.message_POSTED') }}</template>
              </input-textarea>
              <input-textarea
                v-model="restaurant.chatMessage1"
                :inputplaceholder="$t('dashboard.ACCEPTED_placeholder')"
              >
                <template #title>{{
                  $t('dashboard.message_ACCEPTED')
                }}</template>
              </input-textarea>
            </div>
            <label class="text-gray-600">{{
              $t('dashboard.info_chatmessages3')
            }}</label>
            <div class="relative left-11 mt-2 mb-3">
              <li class="text-black">
                {{ "{{" }}<strong class="text-black">time</strong>}}
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  style="display: inline-block"
                  class="h-6 w-6 mr-1"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M13 7l5 5m0 0l-5 5m5-5H6"
                  /></svg
                >{{ $t('dashboard.var_time') }}
              </li>
              <li class="text-black">
                {{ "{{" }}<strong class="text-black">minutes</strong>}}
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  style="display: inline-block"
                  class="h-6 w-6 mr-1"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M13 7l5 5m0 0l-5 5m5-5H6"
                  /></svg
                >{{ $t('dashboard.var_minutes') }}
              </li>
            </div>
            <p class="mb-2">
              <strong class="text-black">{{
                $t('dashboard.at_delivery')
              }}</strong>
            </p>
            <div class="grid grid-rows-1 grid-flow-col gap-5 mb-5">
              <input-textarea
                v-model="restaurant.chatMessage2"
                :inputplaceholder="$t('dashboard.TIMED_placeholder')"
              >
                <template #title>{{ $t('dashboard.message_TIMED') }}</template>
              </input-textarea>
              <input-textarea
                v-model="restaurant.chatMessage3"
                :inputplaceholder="$t('dashboard.INDELIVERY_placeholder')"
              >
                <template #title>{{
                  $t('dashboard.message_INDELIVERY')
                }}</template>
              </input-textarea>
            </div>
            <p class="mb-2">
              <strong class="text-black">{{
                $t('dashboard.at_pickup')
              }}</strong>
            </p>
            <div class="grid grid-rows-1 grid-flow-col gap-5 mb-5">
              <input-textarea
                v-model="restaurant.chatMessage4"
                :inputplaceholder="$t('dashboard.TIMED2_placeholder')"
              >
                <template #title>{{ $t('dashboard.message_TIMED') }}</template>
              </input-textarea>
              <input-textarea
                v-model="restaurant.chatMessage5"
                :inputplaceholder="$t('dashboard.READYFORPICKUP_placeholder')"
              >
                <template #title>{{
                  $t('dashboard.message_READYFORPICKUP')
                }}</template>
              </input-textarea>
            </div>
          </menu-tab>
          <menu-tab :title="$t('dashboard.locations')">
            <h4 class="mb-5">{{ $t('dashboard.locations') }}</h4>
            <div class="grid grid-cols-4 gap-10">
              <location-card
                v-for="location in restaurant.locations"
                :location="location"
                :isModalVisible="isModalVisible"
                :key="location.uuid"
                @remove="deleteLocation(location)"
              />
              <transition name="slide-fade">
                <delete-location
                  v-if="isDeleteLocationModalVisible"
                  @close="closeIsDeleteLocationModalVisible"
                  :location="locationToDelete"
                />
              </transition>
              <div
                class="
                  border border-gray-300 border-dashed
                  rounded-md
                  flex
                  h-full
                  min-h-16
                "
              >
                <button-b
                  type="button"
                  @click.native="addLoc"
                  class="bg-primary m-auto"
                  >{{ $t('dashboard.new_location') }}</button-b
                >
              </div>
            </div>
          </menu-tab>
          <menu-tab :title="$t('dashboard.metafields')">
            <settings-metafields />
          </menu-tab>
          <menu-tab :title="$t('dashboard.discount')">
            <div class="flex">
              <div class="flex-1">
                  <h4 class="mb-5">{{ $t('dashboard.discount') }}</h4>
              </div>
              <div class="flex">
                  <button-b type="button" @click.native="addModal" class="bg-primary m-auto">{{ $t('dashboard.discount_add') }}</button-b>
              </div>
            </div>
            <transition name="slide-fade">
            <add-discount v-if="discountVisible" @close="discountVisible = false" :discountList="discountList"/>
            </transition>
              <checkbox class="mb-4" v-model="restaurant.discountActive" checkboxname="discount"><span class="text-base">{{ $t('dashboard.discountactive') }}</span></checkbox>
              <div v-for="discount in discountList.discounts" :key="discount.uuid">
                <!-- This example requires Tailwind CSS v2.0+ -->
                <div class="bg-white shadow overflow-hidden sm:rounded-md">
                  <ul class="divide-y divide-gray-200">
                    <li>
                      <a class="block hover:bg-gray-50">
                        <div class="flex items-center px-4 py-4 sm:px-6">
                          <div @click="discountEdit = discount.uuid" class="min-w-0 flex-1 flex items-center cursor-pointer">
                            <div class="flex-shrink-0">
                              <!---<div v-if="discount.specification.specificationType === 'PERCENTAGE'" class="border-2 w-7 h-7 rounded-full border-gray-300">
                                <b class="ml-1">%</b>
                              </div>
                              --->
                              <div class="border-2 w-7 h-7 rounded-full border-opacity-60" :class="{'border-green':discount.type === 'AMOUNT', 'border-yellow':discount.type === 'PERCENTAGE'}">
                                <b class="text-black" :class="{'ml_7': discount.type === 'AMOUNT', 'ml_5': discount.type === 'PERCENTAGE'}">{{$t(`dashboard.icon_${discount.type}`)}}</b>
                              </div>
                            </div>
                            <div class="min-w-0 flex-1 px-4 md:grid md:grid-cols-3 md:gap-4">
                              <div class="my-auto">
                                <p class="text-m font-bold text-primary truncate">{{discount.code}}</p>
                              </div>
                              <div class="my-auto">
                                <p v-if="discount.specification.type == 'PRODUCT'" class="text-m font-bold text-black truncate">{{$t('dashboard.product')}}: <span class="text-gray-500">{{(catalog.products.find(c => c.uuid === discount.specificationId) || {}).title }}</span></p>
                                <p v-if="discount.specification.type == 'CATEGORY'" class="text-m font-bold text-black truncate">{{$t('dashboard.category')}}: <span class="text-gray-500">{{(catalog.categories.find(c => c.uuid === discount.specificationId) || {}).title }}</span></p>
                                <p v-if="discount.specification.type == 'GENERAL'" class="text-m font-bold text-black truncate">{{$t('dashboard.discount_GENERAL')}}</p>
                              </div>
                              <div class="hidden md:block my-auto">
                                <div class="">
                                  <badge  v-if="discount.type =='AMOUNT'" settings="bg-green mt-2">
                                  <p class="text-sm text-gray-900">
                                    {{$n(discount.value /100, 'currency')}}
                                  </p>
                                  </badge>
                                  <badge v-else settings="bg-yellow mt-2">
                                  <p class="text-sm text-gray-900">
                                    {{discount.value}}%
                                  </p>
                                  </badge>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div @click="discountRemove = discount.uuid" class="cursor-pointer text-black hover:text-primary">
                            <!-- Heroicon name: solid/chevron-right -->
                            <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
                            </svg>
                          </div>
                        </div>
                      </a>
                    </li>
                  </ul>
                </div>
                <transition name="slide-fade">
                <edit-discount v-if="discountEdit == discount.uuid" :discount="discount" :discountList="discountList" @close="discountEdit = ''"/>
                </transition>
                <transition name="slide-fade">
                <delete-discount
                  v-if="discountRemove == discount.uuid"
                  @close="discountRemove = ''"
                  :discount="discount"
                  :discountList="discountList"
                />
                </transition>
            </div>
          </menu-tab>
        </settings-title-bar>
      </card-box>
    </template>
  </dashboard-sidebar>
</template>

<script>
import DashboardSidebar from '@/components/layout/DashboardSidebar.vue'
import SettingsTitleBar from '../../components/dashboard/SettingsTitleBar.vue'
import MenuTab from '../../components/dashboard/MenuTab.vue'
import ButtonA from '../../components/ui/ButtonA.vue'
import IconChevronLeft from '../../components/icons/IconChevronLeft.vue'
import IconCheck from '../../components/icons/IconCheck.vue'
import CardBox from '../../components/dashboard/CardBox.vue'
import InputNormal from '../../components/ui/InputNormal.vue'
import UploadDragAndDrop from '../../components/ui/UploadDragAndDrop.vue'
import InputTextarea from '../../components/ui/InputTextarea.vue'
import LocationCard from '../../components/dashboard/LocationCard.vue'
import ButtonB from '../../components/ui/ButtonB.vue'
import TitleBar from '../../components/dashboard/TitleBar.vue'
import VSwatches from 'vue-swatches'
import 'vue-swatches/dist/vue-swatches.css'
import DeleteLocation from '@/components/modals/DeleteLocation.vue'
import InputPrice from '../../components/ui/InputPrice.vue'
import { Chrome } from 'vue-color'
import Multiselect from 'vue-multiselect'
import UnsplashSearch from '../../components/modals/UnsplashSearch.vue'
import PicturePlaceholder from '../../components/ui/PicturePlaceholder.vue'
import { PaypalSettings } from 'delivery-boosting-2021-model'
import Toggle from '@/components/ui/Toggle.vue'
import AddDiscount from '@/components/modals/AddDiscount'
import SettingsMetafields from '@/components/dashboard/SettingsMetafields.vue'
import EditDiscount from '@/components/modals/EditDiscount'
import Badge from '@/components/ui/Badge.vue'
import DeleteDiscount from '@/components/modals/DeleteDiscount.vue'
import Checkbox from '@/components/ui/Checkbox.vue'

export default {
    name: 'DashboardSettings',
    data () {
        return {
            mounted: false,
            isModalVisible: false,
            isNotificationVisible: false,
            isDeleteLocationModalVisible: false,
            locationToDelete: null,
            value: null,
            color: '#E3523B',
            colorPicker: '',
            picker: false,
            paymentOptions: ['paypal', 'cash', 'localec', 'accountid'],
            paypalSettings: new PaypalSettings({ data () { return {} }, ref: {} }),
            drawerVisible: false,
            discountVisible: false,
            discountEdit: '',
            discountRemove: '',
            discountList: {
                discounts: []
            },
            loading: false,
            timeout: true
        }
    },
    watch: {
        restaurant: {
            immediate: true,
            async handler () {
                try {
                    this.discountList = await this.$store.state.restaurant.getDiscountList()
                    this.paypalSettings = await this.$store.state.restaurant.getPaypalSettings()
                } catch (error) {
                    console.warn(error)
                }
            }
        },
        catalog () {
            if (this.catalog.uuid !== '__loading__' && typeof (this.catalog.methods.find(ind => ind.uuid === ('delivery'))) === 'undefined') {
                this.catalog.addMethod({
                    uuid: 'delivery',
                    price: {
                        amount: 0,
                        currency: 'EUR'
                    }
                })
            }
            if (this.catalog.uuid !== '__loading__' && typeof (this.catalog.methods.find(ind => ind.uuid === ('pick-up'))) === 'undefined') {
                this.catalog.addMethod({
                    uuid: 'pick-up',
                    price: {
                        amount: 0,
                        currency: 'EUR'
                    }
                })
            }
        },
        swatches: {
            immediate: true,
            handler () {
                if (!this.swatches.includes('#E3523B')) {
                    this.swatches.push('#E3523B')
                }
                if (!this.swatches.includes('#F4C31E')) {
                    this.swatches.push('#F4C31E')
                }
                if (!this.swatches.includes('#40CAB3')) {
                    this.swatches.push('#40CAB3')
                }
                if (!this.swatches.includes('#2CA8F1')) {
                    this.swatches.push('#2CA8F1')
                }
                if (!this.swatches.includes('#6360F5')) {
                    this.swatches.push('#6360F5')
                }
                if (!this.swatches.includes('#6a0dad')) {
                    this.swatches.push('#6a0dad')
                }
                if (!this.swatches.includes('#1b981b')) {
                    this.swatches.push('#1b981b')
                }
                if (!this.swatches.includes('#8a9a2e')) {
                    this.swatches.push('#8a9a2e')
                }
            }
        }
    },
    methods: {
        addModal () {
            this.discountVisible = true
        },
        showModal () {
            this.isModalVisible = true
        },
        closeModal () {
            this.isModalVisible = false
        },
        async save () {
            this.loading = true
            await this.restaurant.commit()
            await this.catalog.commit()
            await this.paypalSettings.commit()
            this.loading = false
            this.isNotificationVisible = true
            setTimeout(this.closeNotification, 3000)
        },
        closeNotification () {
            this.isNotificationVisible = false
        },
        addLoc () {
            this.$store.state.restaurant.addLocation()
        },
        showIsDeleteLocationModalVisible () {
            this.isDeleteLocationModalVisible = true
        },
        closeIsDeleteLocationModalVisible () {
            this.isDeleteLocationModalVisible = false
        },
        async deleteLocation (delLocation) {
            this.locationToDelete = delLocation
            this.isDeleteLocationModalVisible = true
        },
        addColor () {
            if (this.colorPicker !== '') {
                this.swatches.push(this.colorPicker.hex)
            }
            this.picker = false
            setTimeout(
                this.timeoutChange, 700)
        },
        showColorPicker () {
            this.picker = true
            setTimeout(
                this.timeoutChange, 700)
        },
        hideColorPicker () {
            this.picker = false
            setTimeout(
                this.timeoutChange, 700)
        },
        timeoutChange () {
            this.timeout = !this.timeout
        }
    },
    firestore () {
        return {
            discounts: this.discountCollection
        }
    },
    computed: {
        catalog () {
            return this.$store.state.catalog
        },
        restaurant () {
            return this.$store.state.restaurant
        },
        header: {
            set (val) {
                this.$store.state.restaurant.headerMedia = [val.toPlainObject()].filter(
                    (m) => m.url
                )
            },
            get () {
                return this.$store.state.restaurant.headerMedia[0]
            }
        },
        general: {
            set (val) {
                this.$store.state.restaurant.generalMedia = [
                    val.toPlainObject()
                ].filter((m) => m.url)
            },
            get () {
                return this.$store.state.restaurant.generalMedia[0]
            }
        },
        swatches: {
            get () {
                return this.$store.state.restaurant.swatches
            },
            set (val) {
                this.$store.state.restaurant.swatches = val
            }
        }
    },
    async created () {
        setInterval(() => { this.i = !this.i; this.$forceUpdate() }, 100)
        if (this.restaurant.locations.length === 1) {
            this.availability.locations.push(this.restaurant.locations[0].uuid)
        }
        global.settingsvue = this
    },
    mounted () {
        this.mounted = true
    },
    components: {
        DashboardSidebar,
        ButtonA,
        IconChevronLeft,
        IconCheck,
        CardBox,
        InputNormal,
        UploadDragAndDrop,
        InputTextarea,
        LocationCard,
        ButtonB,
        TitleBar,
        VSwatches,
        DeleteLocation,
        InputPrice,
        Chrome,
        MenuTab,
        SettingsTitleBar,
        Multiselect,
        UnsplashSearch,
        PicturePlaceholder,
        Toggle,
        AddDiscount,
        SettingsMetafields,
        EditDiscount,
        Badge,
        DeleteDiscount,
        Checkbox
    }
}
</script>

<style>
.ml_7 {
  margin-left: 7px
}

.ml_5 {
  margin-left: 5px
}
.vue-swatches__swatch{
  margin-bottom: 11px;
}

.vc-alpha-checkboard-wrap {
  display: none !important;
}

.vc-chrome-alpha-wrap {
  display: none;
}

.vc-chrome-hue-wrap {
  margin-bottom: 0;
  margin-top: 8px;
}
#fancy {
  animation: loadingAnimation 3s linear;
}

@keyframes loadingAnimation {
  0% {
    width: 100%;
  }
  50% {
    width: 50%;
  }
  100% {
    width: 0%;
  }
}
.slide-fade-enter-active {
  transition: all 0.5s ease;
}
.slide-fade-leave-active {
  transition: all 0.5s ease;
}
.slide-fade-enter, .slide-fade-leave-to
/* .slide-fade-leave-active below version 2.1.8 */ {
  transform: translateY(100px);
  opacity: 0;
}
</style>

<style src="@/assets/css/multiselect.css"></style>
