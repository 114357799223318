<template>
    <modal @close="close">
        <template #title>{{ $t('dashboard.add_discount') }}</template>
        <template #interactive><button-b :loading="loading" class="bg-primary" @click.native="addAndSave">{{ $t('dashboard.add') }}</button-b></template>
        <template #body>
            <div class="grid grid-cols-6 gap-6">
                <div class="col-span-6">
                    <label for="name" class="block text-sm font-bold">{{ $t('dashboard.discount_type') }}</label>
                    <multiselect
                v-model="discount.type"
                :preselectFirst="true"
                :options="discountTypeOptions"
                :placeholder="$t('dashboard.discount_placeholder')"
                :deselectLabel="$t('dashboard.remove')"
                :selectedLabel="$t('dashboard.selected')"
                :selectLabel="$t('dashboard.select')"
              >
                <template slot="noResult">{{
                  $t("dashboard.no_results")
                }}</template>
                <template slot="singleLabel" slot-scope="{ option }"
                  ><strong>{{ $t(`dashboard.discount_${option}`) }}</strong></template
                >
                <template slot="option" slot-scope="{ option }">{{
                 $t(`dashboard.discount_${option}`)
                }}</template>
              </multiselect>
                </div>
                <div class="col-span-6 sm:col-span-3">
                    <input-normal
                  v-model="discount.code"
                  inputtype="text"
                  inputname="name"
                  inputid="name"
                  :inputplaceholder="$t('dashboard.discount_code')"
                >
                  <template #title>{{ $t('dashboard.discount_code') }}</template>
                </input-normal>
                </div>
                <div class="col-span-6 sm:col-span-3">
                    <input-price
                    v-if="discount.type === 'AMOUNT'"
                  v-model="discount.value"
                  inputtype="text"
                  inputname="name"
                  inputid="name"
                  inputplaceholder="10,50"
                >
                  <template #title>{{ $t('dashboard.discount_value') }}</template>
                </input-price>
                <input-percentage
                  v-if="discount.type === 'PERCENTAGE'"
                  v-model="discount.value"
                  inputname="name"
                  inputid="name"
                  inputplaceholder="35"
                >
                  <template #title>{{ $t('dashboard.discount_value') }}</template>
                    <template #icon>
                        <span class="w-5 h-5 absolute top-1/2 transform right-8 -translate-y-1/2 text-black-400">
                        %
                        </span>
                    </template>
                </input-percentage>
                </div>
                <div class="col-span-6 sm:col-span-3">
                    <label for="name" class="block text-sm font-bold">{{ $t('dashboard.discount_timefrom') }}</label>
                    <datepicker-full v-model="discount.timeFrame.from">
                        <template #icon>
                            <icon-calendar/>
                        </template>
                        </datepicker-full>
                </div>
                <div class="col-span-6 sm:col-span-3">
                    <label for="name" class="block text-sm font-bold">{{ $t('dashboard.discount_timeto') }}</label>
                    <label for="name" class="block text-sm font-bold"></label>
                    <datepicker-block v-model="discount.timeFrame.to">
                        </datepicker-block>
                </div>
                <div class="col-span-6 sm:col-span-2">
                    <label for="name" class="block text-sm font-bold">{{ $t('dashboard.discount_spec') }}</label>
                    <multiselect
                v-model="discount.specification.type"
                :preselectFirst="true"
                :options="discountSpecification"
                :placeholder="$t('dashboard.discounttype_placeholder')"
                :deselectLabel="$t('dashboard.remove')"
                :selectedLabel="$t('dashboard.selected')"
                :selectLabel="$t('dashboard.select')"
              >
                <template slot="noResult">{{
                  $t("dashboard.no_results")
                }}</template>
                <template slot="singleLabel" slot-scope="{ option }"
                  ><strong>{{ $t(`dashboard.discount_${option}`) }}</strong></template
                >
                <template slot="option" slot-scope="{ option }">{{
                 $t(`dashboard.discount_${option}`)
                }}</template>
              </multiselect>
                </div>
                <div class="col-span-6 sm:col-span-4">
                    <label v-if="discount.specification.type === 'PRODUCT'" for="name" class="block text-sm font-bold">{{ $t('dashboard.product') }}</label>
                    <multiselect
                v-if="discount.specification.type === 'PRODUCT'"
                v-model="specificationId"
                :options="catalog.products"
                :placeholder="$t('dashboard.discountProd_placeholder')"
                :deselectLabel="$t('dashboard.remove')"
                :selectedLabel="$t('dashboard.selected')"
                :selectLabel="$t('dashboard.select')"
              >
                <template slot="noResult">{{
                  $t("dashboard.no_results")
                }}</template>
                <template slot="singleLabel" slot-scope="{ option }"
                  ><strong v-if="option != undefined">{{(catalog.products.find(c => c.uuid == discount.specification.id)).title}}</strong></template
                >
                <template slot="option" slot-scope="{ option }">
                    {{option.title}}
                </template>
              </multiselect>
              <label v-if="discount.specification.type === 'CATEGORY'" for="name" class="block text-sm font-bold">{{ $t('dashboard.category') }}</label>
              <multiselect
                v-if="discount.specification.type === 'CATEGORY'"
                v-model="specificationId"
                :options="catalog.categories"
                :placeholder="$t('dashboard.discountCat_placeholder')"
                :deselectLabel="$t('dashboard.remove')"
                :selectedLabel="$t('dashboard.selected')"
                :selectLabel="$t('dashboard.select')"
              >
                <template slot="noResult">{{
                  $t("dashboard.no_results")
                }}</template>
                <template slot="singleLabel" slot-scope="{ option }"
                  ><strong v-if="option != undefined">{{(catalog.categories.find(c => c.uuid == discount.specification.id)).title}}</strong></template
                >
                <template slot="option" slot-scope="{ option }">
                    {{option.title}}
                </template>
              </multiselect>
                </div>
                 <div class="col-span-6 sm:col-span-3">
              <input-price
                  v-model="discount.minValue"
                  inputtype="text"
                  inputname="name"
                  inputid="name"
                  inputplaceholder="10,50"
                >
                  <template #title>{{ $t('dashboard.discount_minvalue') }}</template>
                </input-price>
                 </div>
            </div>
        </template>
    </modal>
</template>

<script>
import InputNormal from '../../components/ui/InputNormal.vue'
import ButtonB from '../../components/ui/ButtonB.vue'
import Modal from '../../components/dashboard/Modal.vue'
// import ButtonL from '../ui/ButtonL.vue'
import Multiselect from 'vue-multiselect'
import InputPrice from '../../components/ui/InputPrice.vue'
import InputPercentage from '../../components/ui/InputPercentage.vue'
import DatepickerFull from '@/components/ui/DatepickerFull.vue'
import DatepickerBlock from '@/components/ui/DatepickerBlock.vue'
import IconCalendar from '@/components/icons/IconCalendar.vue'
import { Discount, DiscountList } from 'delivery-boosting-2021-model'

export default {
    name: 'DashboardSettings',
    data () {
        return {
            x: false,
            discount: {
                code: '',
                type: '',
                value: 0,
                timeFrame: {
                    from: '',
                    to: ''
                },
                specification: {
                    type: '',
                    id: ''
                },
                minValue: 0,
                createdAt: new Date()
            },
            discountTypeOptions: ['AMOUNT', 'PERCENTAGE'],
            discountSpecification: ['GENERAL', 'PRODUCT', 'CATEGORY'],
            loading: false
        }
    },
    methods: {
        close () {
            this.$emit('close')
        },
        async addAndSave () {
            this.loading = true
            await this.discountList.addDiscount(new Discount(this.discount))
            this.loading = false
            this.close()
        }
    },
    props: {
        discountList: {
            Type: DiscountList
        }
    },
    components: {
        InputNormal,
        ButtonB,
        Modal,
        Multiselect,
        InputPrice,
        InputPercentage,
        DatepickerFull,
        DatepickerBlock,
        IconCalendar
    },
    computed: {
        restaurant () {
            return this.$store.state.restaurant
        },
        catalog () {
            return this.$store.state.catalog
        },
        specificationId: {
            get () {
                // eslint-disable-next-line no-unused-expressions
                this.x
                return this.discount.specification.id
            },
            set (val) {
                // eslint-disable-next-line no-unused-expressions
                this.x = !this.x
                this.discount.specification.id = val.uuid
                this.x = !this.x
            }
        }
    }
}
</script>

<style src="@/assets/css/multiselect.css"></style>
